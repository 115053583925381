/* eslint no-console: "off" */

export const informer = () => {
  console.log('[ENV] DISABLE_ESLINT_PLUGIN', process.env.DISABLE_ESLINT_PLUGIN);

  console.log('[ENV] REACT_APP_API_URL', process.env.REACT_APP_API_URL);
  console.log('[ENV] REACT_APP_API_MOCKS', process.env.REACT_APP_API_MOCKS);

  console.log('[ENV] REACT_APP_ENABLE_VITALS', process.env.REACT_APP_ENABLE_VITALS);
  console.log(
    '[ENV] REACT_APP_ENABLE_GOOGLE_ANALYTICS',
    process.env.REACT_APP_ENABLE_GOOGLE_ANALYTICS,
  );
  console.log('[ENV] REACT_APP_ENABLE_SENTRY', process.env.REACT_APP_ENABLE_SENTRY);
  console.log('[ENV] REACT_APP_GOOGLE_ANALYTICS', process.env.REACT_APP_GOOGLE_ANALYTICS);
  console.log('[ENV] REACT_APP_SENTRY', process.env.REACT_APP_SENTRY);

  console.log('[ENV] REACT_APP_PUSHER_KEY', process.env.REACT_APP_PUSHER_KEY);
  console.log('[ENV] REACT_APP_PUSHER_CLUSTER', process.env.REACT_APP_PUSHER_CLUSTER);

  console.log('[ENV] REACT_APP_FITBIT_API_HOST', process.env.REACT_APP_FITBIT_API_HOST);
};

import {
  Alert,
  Box,
  BoxProps,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  TablePagination,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import SearchIcon from '@mui/icons-material/Search';
import { toast } from 'react-toastify';
import { usePreferredTranslation } from '../../../../hooks/usePreferredTranslation';
import {
  MessageEntity,
  UserEntity,
  useMessagesQuery,
  useSendMmsMutation,
} from '../../../../graphql';
import { useChannel, useEvent } from '../../../../modules';
import { useCurrentUser, useUser } from '../../../../states';

import { Attachment } from '../../attachments';
import { Controls } from '../../controls';
import { Message } from '../Message';
import { MessageCardsPlaceholder } from '../../../atoms';
import NoMessageIcon from '../../../../assets/icons/no_message.svg';
import { PUSHER } from '../../../../constants';
import { PhoneNumberEntity } from '../../../../openapi';
import { UserRoles } from '../../../../constants/users';
import { scrollListToEnd } from '../../../../utils';
import { theme } from '../../../../theme';
import { withValidStudyUsers } from '../../../hoc/withValidStudyUsers';

interface Properties extends BoxProps {
  readonly user: UserEntity | null;
  readonly onSelectMessage: (message: MessageEntity | null) => void;
  readonly selectedMessage: MessageEntity | null;
  readonly hasSearch?: boolean;
  readonly hasPagination?: boolean;
  phoneNumber?: PhoneNumberEntity;
}

const MessagesComp = ({
  user,
  onSelectMessage,
  selectedMessage,
  hasSearch = true,
  hasPagination = true,
  phoneNumber,
  ...rest
}: Properties) => {
  const { t } = usePreferredTranslation();
  const { studyId, studyArmId, checkUserRole } = useUser();

  const [search, setSearch] = useState('');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [channelMessages, setChannelMessages] = useState<MessageEntity[]>([]);

  const currentUser = useCurrentUser();

  const [sendMmsMutation, sendMmsMutationVariables] = useSendMmsMutation();

  const listReference = useRef<HTMLUListElement | null>(null);

  const messagesQuery = useMessagesQuery({
    fetchPolicy: 'network-only',
    variables: {
      search,
      user: Number(currentUser.data?.user?.id),
      correspondingUser: user?.id ? Number(user.id) : undefined,
      studyId,
      limit: {
        take: rowsPerPage,
        skip: page * rowsPerPage,
      },
    },
    skip: !currentUser.data?.user?.id,
  });

  const messages = useMemo<MessageEntity[]>(
    () => [...channelMessages, ...(messagesQuery.data?.messages.items || [])].reverse(),
    [channelMessages, messagesQuery],
  );
  const loading = currentUser.loading || messagesQuery.loading;

  useEffect(() => {
    scrollListToEnd(listReference, false);
  }, [loading]);

  useEffect(() => {
    scrollListToEnd(listReference, true);
  }, [messages]);

  const count = (messagesQuery.data?.messages.count || 0) + channelMessages.length;

  const messagesChannel = useChannel(PUSHER.CHANNELS.MESSAGES);

  useEvent<MessageEntity>(
    messagesChannel,
    `${PUSHER.EVENTS.MESSAGE_CREATED}.${currentUser.data?.user?.id || ''}`,
    (message?: MessageEntity) => {
      if (message) {
        setPage(0);
        setChannelMessages([]);
        messagesQuery.refetch();
      }
    },
  );
  useEvent<number>(
    messagesChannel,
    `${PUSHER.EVENTS.MESSAGES_UPDATED}.${currentUser.data?.user?.id || ''}`,
    (c?: number) => {
      if (c) {
        setPage(0);
        setChannelMessages([]);
        messagesQuery.refetch();
      }
    },
  );

  const handlePageChange = (_: MouseEvent<HTMLButtonElement> | null, value: number) => {
    setChannelMessages([]);
    setPage(value);
  };

  const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setChannelMessages([]);
    setRowsPerPage(Number.parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
      setChannelMessages([]);
      setPage(0);
    },
    [setSearch, setChannelMessages, setPage],
  );

  const handleSearchReset = useCallback(() => {
    setSearch('');
    setChannelMessages([]);
    setPage(0);
  }, [setSearch, setChannelMessages, setPage]);

  const handleSubmitting = useCallback(
    async (text: string, attachments: Attachment[]) => {
      const data = await sendMmsMutation({
        variables: {
          user: user?.id ? Number(user?.id) : null,
          text,
          files: attachments.map((attachment) => attachment.file),
          studyId,
          studyArmId,
          phoneNumber: phoneNumber ? JSON.stringify(phoneNumber) : '',
        },
      });

      setTimeout(() => {
        scrollListToEnd(listReference, true);
      }, 100);

      if (data.errors?.length) {
        toast.error(data.errors[0]?.message || 'Error');
      }
    },
    [sendMmsMutation, studyArmId, studyId, user?.id],
  );

  const handleResetting = useCallback(() => {}, []);

  return (
    <Box
      {...rest}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        overflow: 'hidden',
      }}
    >
      {hasSearch && (
        <Box
          sx={{
            p: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {hasSearch && (
            <TextField
              fullWidth
              placeholder={t('Search in chat')}
              type="text"
              variant="outlined"
              size="small"
              onChange={handleSearchChange}
              value={search}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),

                endAdornment: search && (
                  <IconButton aria-label="Reset search messages..." onClick={handleSearchReset}>
                    <CancelRoundedIcon />
                  </IconButton>
                ),
              }}
            />
          )}
        </Box>
      )}
      {currentUser.loading || messagesQuery.loading ? (
        <MessageCardsPlaceholder />
      ) : (
        <List
          sx={{ p: 0, overflow: 'auto', flexGrow: 1, flexDirection: 'column-reverse' }}
          ref={(r) => {
            listReference.current = r;
          }}
        >
          {messages.length === 0 &&
            (checkUserRole(currentUser.data?.user, UserRoles.PARTICIPANT) ? (
              <Box
                width="100%"
                height="100%"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  width="60%"
                  height="100%"
                >
                  <img src={NoMessageIcon} alt="No messages" />
                  <Typography variant="h4">{t('No messages')}</Typography>
                  <Typography variant="body1">
                    {t(
                      'Questions, conversations, and topics related to your study with our research team will show up here. If you have a question at any time, leave us a message and someone will get back to you.',
                    )}
                  </Typography>
                </Box>
              </Box>
            ) : (
              <ListItem>
                <Alert sx={{ width: '100%' }} severity="error">
                  {t('Nothing found')}
                </Alert>
              </ListItem>
            ))}

          {messages?.map((message: MessageEntity) => (
            <ListItem
              key={message.id}
              onClick={() => onSelectMessage(message)}
              sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                borderColor: theme.palette.divider,
                p: 1,
              }}
            >
              <Message
                owner={String(message.sender?.id) === String(currentUser.data?.user?.id)}
                message={message}
                isGroupChat={messagesQuery.data?.messages.isGroupChat}
              />
            </ListItem>
          ))}
        </List>
      )}
      {!loading && hasPagination && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          {hasPagination && (
            <TablePagination
              component="div"
              count={count}
              page={page}
              onPageChange={handlePageChange}
              labelRowsPerPage={t('Rows per page:')}
              nextIconButtonProps={{
                'aria-label': t('Go to next page'),
                title: t('Go to next page'),
              }}
              backIconButtonProps={{
                'aria-label': t('Go to previous page'),
                title: t('Go to previous page'),
              }}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          )}
        </Box>
      )}
      {!loading && (
        <Controls
          onSubmitting={handleSubmitting}
          onResetting={handleResetting}
          loading={messagesQuery.loading || sendMmsMutationVariables.loading}
          sx={{
            border: '1px solid',
            borderLeft: 'none',
            borderBottom: 'none',
            borderRight: 'none',
            borderColor: (t) => t.palette.divider,
          }}
        />
      )}
    </Box>
  );
};

const Messages = withValidStudyUsers(MessagesComp);

export { Messages };
